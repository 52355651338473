<template>
  <b-card-code class="reminder-event-page">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between flex-wrap">
      <b-form-group>
        <b-link
          variant="warning"
          target="_blank"
          class="btn btn-primary mr-1"
          href="/reminder-events/create"
        >
          Create
        </b-link>
        <b-button
          class="btn btn-warning"
          @click="fetchReminderEvents()"
        >
          <feather-icon
            icon="RefreshCwIcon"
            size="14"
          />
        </b-button>
      </b-form-group>

      <b-form-group>
        <div class="d-flex align-items-center">
          <v-select
            v-model="type"
            :options="types"
            label="name"
            class="search-box"
          />
          <b-form-input
            v-model="searchTerm"
            v-debounce:300ms="handleSearch"
            placeholder="Search"
            type="text"
            class="d-inline-block ml-1"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="reminderEvents && reminderEvents.data"
      :pagination-options="{
        enabled: true,
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: { field: 'left_days', type: 'asc' },
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: id -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.id }}
        </span>

        <!-- Column: name -->
        <span
          v-else-if="props.column.field === 'name'"
        >
          {{ props.row.name }}
          <br>
          {{ props.row.en_name }}
        </span>

        <!-- Column: is_solar -->
        <span v-else-if="props.column.field === 'is_solar'">
          <EventType :is-solar="props.row.is_solar == 1" />
        </span>

        <!-- Column: day -->
        <span v-else-if="props.column.field === 'day'">
          <span v-if="props.row.type === 4">
            {{ props.row.the_day }}
          </span>
          <span v-else>
            {{ $commonFunc.minTwoDigits(props.row.month) }}-{{ $commonFunc.minTwoDigits(props.row.day) }}
          </span>
        </span>

        <!-- Column: left_days -->
        <span v-else-if="props.column.field === 'left_days'">
          {{ $commonFunc.leftDaysForHuman(props.row.left_days) }}
          <div v-if="props.row.type === 4">
            <span>{{ props.row.count_days }}</span>
            <br>
            <span>{{ props.row.human_count_days }}</span>
            <br>
            <span>{{
              $commonFunc.diffDateForHuman(
                $commonFunc.createReminderEvent(
                  props.row.year,
                  props.row.month,
                  props.row.day,
                  props.row.type)
              )
            }}</span>
          </div>
        </span>

        <!-- Column: DoW -->
        <span v-else-if="props.column.field === 'DoW'">
          {{ $commonFunc.weekDaysFromNow(props.row.left_days) }}
        </span>

        <!-- Column: type -->
        <span
          v-else-if="props.column.field === 'type'"
          class="badge badge-glow text-white"
          :class="colorEventType(props.row.type)"
        >
          {{ textEventType(props.row.type) }}
        </span>

        <!-- Column: action -->
        <span v-else-if="props.column.field === 'action'">
          <b-button
            size="sm"
            variant="info"
            target="_blank"
            :to="{ path: '/reminder-events/' + props.row.id }"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

        </span>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom">
        <div class="d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap">
              of {{ reminderEvents.total }} entries
            </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="1"
              :total-rows="reminderEvents && reminderEvents.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import { pagination } from '@core/mixins/pagination'

import EventType from './components/EventType.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('reminderEvent')

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    EventType,
  },
  mixins: [toastification, pagination],
  data() {
    return {
      searchTerm: '',
      sortTerm: {
        field: '',
        type: '',
      },
      type: null,
      types: [
        { name: 'Global', val: 1 },
        { name: 'Yearly', val: 2 },
        { name: 'Dynamic', val: 3 },
        { name: 'Anniversary', val: 4 },
        { name: 'Personal', val: 5 },
      ],
      columns: [
        {
          label: 'Name', field: 'name', thClass: 'text-nowrap', width: '40%',
        },
        {
          label: 'Day', field: 'day', thClass: 'text-nowrap', tdClass: 'text-center text-nowrap',
        },
        {
          label: 'Solar', field: 'is_solar', thClass: 'text-nowrap', tdClass: 'text-center',
        },
        { label: 'Solar day', field: 'solar_day', tdClass: 'text-nowrap' },
        {
          label: 'Left days', field: 'left_days', thClass: 'text-nowrap', tdClass: 'text-right text-nowrap',
        },
        {
          label: 'DoW', field: 'DoW', thClass: 'text-nowrap', tdClass: 'text-right',
        },
        { label: 'Type', field: 'type', thClass: 'text-nowrap' },
        { label: 'Action', field: 'action', thClass: 'text-nowrap' },
      ],
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading', 'reminderEvents']),

    textEventType() {
      return value => {
        switch (value) {
          case 1:
            return 'Global'
          case 2:
            return 'Yearly'
          case 3:
            return 'Dynamic'
          case 4:
            return 'Anniversary'
          case 5:
            return 'Personal'
          default:
            return 'Unknown'
        }
      }
    },
    colorEventType() {
      return value => {
        switch (value) {
          case 1:
            return 'badge-light-danger'
          case 2:
            return 'badge-light-warning'
          case 3:
            return 'badge-light-info'
          case 4:
            return 'badge-light-primary'
          case 5:
            return 'badge-light-success'
          default:
            return 'badge-light-primary'
        }
      }
    },

  },

  watch: {
    async type() {
      await this.fetchReminderEvents()
    },
  },

  methods: {
    ...mapActions(['getReminderEvents']),

    async handleSearch(searching) {
      this.searchTerm = searching
      this.currentPage = 1
      await this.fetchReminderEvents()
    },
    async handlePageChange(active) {
      this.pageLength = active
      await this.fetchReminderEvents()
    },
    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchReminderEvents()
    },
    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type

      await this.fetchReminderEvents()
    },

    async fetchReminderEvents() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        type: this.type ? this.type.val : null,
        sort:
          this.sortTerm.type === 'asc'
            ? `${this.sortTerm.field}`
            : `-${this.sortTerm.field}`,
        search: this.searchTerm,
      }

      await this.getReminderEvents(params)
    },
  },
}
</script>
