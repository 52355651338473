<template>
  <div>
    <feather-icon
      v-if="isSolar"
      :color="klor"
      icon="SunIcon"
      size="24"
    />
    <feather-icon
      v-else
      color="orange"
      icon="MoonIcon"
      size="24"
    />
  </div>
</template>

<script>
import store from '@/store/index'

export default {
  props: {
    isSolar: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    klor() {
      const { skin } = store.state.appConfig.layout
      return skin === 'dark' ? 'yellow' : 'red'
    },
  },
}
</script>
